import React, { Component, ReactNode } from 'react'

import * as Sentry from '@sentry/nextjs'
import ErrorPage from 'core/components/ErrorPage'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
  error?: unknown
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error }
  }

  public componentDidCatch(error: Error) {
    Sentry.captureException(error)
  }

  public render() {
    const { hasError } = this.state
    const { children } = this.props

    return hasError ? <ErrorPage /> : children
  }
}

export default ErrorBoundary
