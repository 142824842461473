import React from 'react'

import { Analytics as VercelAnalytics } from '@vercel/analytics/react'
import Analytics from 'core/components/Analytics'
import ErrorBoundary from 'core/components/ErrorBoundary'
import Widget from 'core/components/Widget'
import RegionConfigProvider from 'core/providers/region'
import UserProvider from 'core/providers/user'
import { SWRConfig } from 'swr'

import 'styles/globals.css'
import 'styles/calendar.css'
import 'styles/tide.css'

function App({
  Component,
  pageProps: {
    region,
    regionConfig,
    embedId,
    user,
    fallback = {},
    ...pageProps
  },
}) {
  const getLayout = Component.getLayout || ((page) => page)

  return (
    <>
      <ErrorBoundary>
        <RegionConfigProvider region={region} regionConfig={regionConfig}>
          <Analytics />
          <Widget embedId={embedId} />
          <UserProvider user={user}>
            <SWRConfig
              value={{
                fallback,
                focusThrottleInterval: 300_000,
              }}
            >
              {getLayout(<Component {...pageProps} />)}
            </SWRConfig>
          </UserProvider>
        </RegionConfigProvider>
      </ErrorBoundary>
      <VercelAnalytics />
    </>
  )
}

export default App
