export const statusCodeToError = (statusCode?: number) => {
  switch (statusCode) {
    case 400:
      return {
        title: `400, Bad Request`,
        description: `Something went wrong, please reload the page and try again.`,
        hasReloadButton: true,
      }
    case 401:
      return {
        title: `401, Unauthorized`,
        description: `You do not have permission to view this page.`,
        hasReloadButton: false,
      }
    case 403:
      return {
        title: `403, Forbidden`,
        description: `You do not have permission to access from this server.`,
        hasReloadButton: false,
      }
    case 404:
      return {
        title: `404, Not found`,
        description: `This page does not exist.`,
        hasReloadButton: false,
      }
    case 500:
      return {
        title: `500, Internal server error`,
        description: `The server has encountered an internal error.`,
        hasReloadButton: false,
      }
    default:
      return {
        title: `Oops!`,
        description: `Something went wrong. Try refreshing the page.`,
        hasReloadButton: true,
      }
  }
}
