import React from 'react'

import cx from 'clsx'

import styles from './styles.module.css'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  secondary?: boolean
  tertiary?: boolean
  utility?: boolean
  danger?: boolean
  small?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    className,
    children,
    secondary,
    tertiary,
    utility,
    danger,
    small,
    ...props
  },
  ref
) {
  const buttonClassName = cx([
    styles.Button,
    {
      [styles.Button_Small]: small,
      [styles.Button_Secondary]: secondary,
      [styles.Button_Tertiary]: tertiary,
      [styles.Button_Utility]: utility,
      [styles.Button_Danger]: danger,
    },
    className,
  ])
  return (
    <button ref={ref} className={buttonClassName} {...props}>
      {children}
    </button>
  )
})

export default Button
