import React from 'react'

import cx from 'clsx'
import Button from 'core/components/Button'
import DizzyBot from 'core/icons/DizzyBot'
import { statusCodeToError } from 'core/utils/statusCodes'
import Router from 'next/router'

import styles from './styles.module.css'

interface Props {
  statusCode?: number
  className?: string
}

const ErrorPage = React.forwardRef<HTMLDivElement, Props>(
  ({ statusCode, className, ...props }, ref) => {
    const { description, title, hasReloadButton } =
      statusCodeToError(statusCode)

    return (
      <div
        ref={ref}
        className={cx(styles.ErrorContainer, className)}
        {...props}
      >
        <div className={styles.DizzyBotContainer}>
          <div className={styles.DizzyBotBackground} />
          <DizzyBot />
        </div>
        <div className={styles.ErrorPageTitle}>{title}</div>
        {description && (
          <div className={styles.ErrorPageDescription}>{description}</div>
        )}
        {hasReloadButton && (
          <Button
            onClick={() => Router.reload()}
            className={styles.ErrorPageCTA}
          >
            Refresh
          </Button>
        )}
      </div>
    )
  }
)

ErrorPage.displayName = 'ErrorPage'

export default ErrorPage
