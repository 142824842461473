import React, { useEffect } from 'react'

import * as snippet from '@segment/snippet'
import Script from 'next/script'

const DEFAULT_KEY = '26F56Q24FYGhT79jXwkS3Ax002VxcoCN' // QA

const renderSnippet = () => {
  const options = {
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_API_KEY || DEFAULT_KEY,
    page: true,
  }

  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
    return snippet.min(options)
  } else {
    return snippet.max(options)
  }
}

export default function Analytics() {
  useEffect(() => {
    window.analytics.ready(function () {
      window.addEventListener('drift::emailCapture', function (e) {
        window.analytics.identify({
          email: e.data.email,
        })
        window.analytics.track('Email Captured', {
          emailValue: e.data.email,
        })
      })
    })
  }, [])

  return (
    <Script
      id="analytics-snippet"
      dangerouslySetInnerHTML={{ __html: renderSnippet() }}
    />
  )
}
