import { PropsWithoutRef } from 'react'

export default function DizzyBot({
  width = '80',
  height = '80',
  viewBox = '0 0 80 80',
  fill = 'none',
  ...props
}: PropsWithoutRef<JSX.IntrinsicElements['svg']>) {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fill} {...props}>
      <g clipPath="url(#clip0_663_11587)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.459 13.4114H41.4829V21.0576H46.0187V24.1169H65.6456C66.9137 24.1169 67.9418 25.1449 67.9418 26.413V56.9949C67.9418 58.263 66.9137 59.2911 65.6456 59.2911H42.1225V63.1071H37.8193V59.2911H14.2962C13.028 59.2911 12 58.263 12 56.9949V26.413C12 25.1449 13.028 24.1169 14.2962 24.1169H33.9231V21.0576H38.459V13.4114ZM37.8193 63.1072C30.6895 63.1072 24.9096 68.887 24.9096 76.0168V78.1684C24.9096 79.3567 25.8729 80.32 27.0612 80.32H52.8805C54.0688 80.32 55.0321 79.3567 55.0321 78.1684V76.0168C55.0321 68.887 49.2523 63.1072 42.1225 63.1072H37.8193Z"
          fill="#353F45"
        />
        <rect
          x="19.0725"
          y="35.8933"
          width="41.5784"
          height="12.9992"
          rx="6.49958"
          transform="rotate(-5 19.0725 35.8933)"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.088 40.8118C55.0474 43.1342 53.1533 44.9843 50.8574 44.9442C48.5614 44.9042 46.733 42.989 46.7736 40.6667C46.8141 38.3444 48.7082 36.4942 51.0042 36.5343C53.3001 36.5744 55.1285 38.4895 55.088 40.8118ZM33.9241 40.4428C33.8836 42.7652 31.9895 44.6153 29.6935 44.5752C27.3975 44.5351 25.5692 42.62 25.6097 40.2977C25.6502 37.9753 27.5443 36.1252 29.8403 36.1653C32.1363 36.2054 33.9646 38.1205 33.9241 40.4428Z"
          fill="#353F45"
        />
        <path
          d="M39.2338 17.9399C45.2237 19.0243 50.7229 19.6775 54.7781 19.8524C56.7982 19.9395 58.5015 19.91 59.7405 19.7426C60.3529 19.6598 60.9138 19.5358 61.3576 19.3416C61.7512 19.1693 62.3525 18.8077 62.4766 18.0852C62.6007 17.3626 62.1561 16.8116 61.8436 16.5116C61.4913 16.1732 61.0055 15.8597 60.4577 15.5669C59.3491 14.9745 57.7584 14.3487 55.8311 13.7212C51.9622 12.4616 46.577 11.1443 40.5871 10.0599C34.5972 8.97556 29.098 8.32239 25.0428 8.1475C23.0226 8.06038 21.3194 8.08989 20.0803 8.2573C19.468 8.34003 18.907 8.46407 18.4632 8.6583C18.0697 8.83054 17.4684 9.19219 17.3443 9.91471C17.2202 10.6372 17.6648 11.1882 17.9772 11.4883C18.3295 11.8266 18.8153 12.1402 19.3632 12.4329C20.4718 13.0253 22.0625 13.6512 23.9898 14.2787C27.8587 15.5383 33.2438 16.8556 39.2338 17.9399Z"
          stroke="#EFF3F5"
          strokeWidth="2"
        />
        <path
          d="M40.587 17.94C46.5769 16.8556 51.9621 15.5383 55.831 14.2787C57.7583 13.6512 59.349 13.0254 60.4576 12.433C61.0055 12.1402 61.4912 11.8267 61.8436 11.4883C62.156 11.1883 62.6006 10.6373 62.4765 9.91475C62.3524 9.19223 61.7511 8.83058 61.3575 8.65834C60.9137 8.46411 60.3528 8.34007 59.7404 8.25734C58.5014 8.08993 56.7981 8.06042 54.778 8.14754C50.7228 8.32243 45.2236 8.9756 39.2337 10.06C33.2437 11.1444 27.8586 12.4616 23.9897 13.7212C22.0624 14.3487 20.4717 14.9746 19.3631 15.567C18.8152 15.8598 18.3294 16.1733 17.9771 16.5116C17.6647 16.8117 17.2201 17.3627 17.3442 18.0852C17.4683 18.8077 18.0696 19.1694 18.4632 19.3416C18.9069 19.5358 19.4679 19.6599 20.0802 19.7426C21.3193 19.91 23.0226 19.9395 25.0427 19.8524C29.0979 19.6775 34.5971 19.0243 40.587 17.94Z"
          stroke="#EFF3F5"
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.9711 14.1759C42.4762 14.1759 44.5069 12.1218 44.5069 9.58794C44.5069 7.05409 42.4762 5 39.9711 5C37.4661 5 35.4353 7.05409 35.4353 9.58794C35.4353 12.1218 37.4661 14.1759 39.9711 14.1759Z"
          fill="#353F45"
        />
      </g>
      <defs>
        <clipPath id="clip0_663_11587">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
