import React from 'react'

export interface RegionConfigProps {
  region: string
  regionConfig: Record<string, string>
}

const RegionConfigContext = React.createContext<RegionConfigProps>({
  region: '',
  regionConfig: {},
})

export const useRegion = () => React.useContext(RegionConfigContext).region
export const useRegionConfig = () =>
  React.useContext(RegionConfigContext).regionConfig

const RegionConfigProvider = ({
  children,
  region,
  regionConfig,
}: React.PropsWithChildren<RegionConfigProps>) => {
  return (
    <RegionConfigContext.Provider
      value={{
        region,
        regionConfig,
      }}
    >
      {children}
    </RegionConfigContext.Provider>
  )
}

export default RegionConfigProvider
