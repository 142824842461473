import { createContext, useContext, useEffect, useState } from 'react'

import axios from 'axios'
const UserContext = createContext(null)

export const useEndUser = () => useContext(UserContext)?.endUser
export const useUserConsentStatus = () =>
  useContext(UserContext)?.didConsentToRequestedConsentId
export const useAccessToken = () => useContext(UserContext)?.accessToken

const UserProvider = ({ children, user }) => {
  const [isClientReady, setIsClientReady] = useState(false)
  useEffect(() => {
    const authInterceptorId = axios.interceptors.request.use((config) => {
      if (/\.drift(qa)?\.com\//.test(config.url)) {
        config.headers.Authorization = `Bearer ${user?.accessToken}`
      }
      return config
    })

    if (user?.accessToken) {
      setIsClientReady(true)
    }

    return () => axios.interceptors.request.eject(authInterceptorId)
  }, [user])

  return (
    <UserContext.Provider value={user}>
      {isClientReady && children}
    </UserContext.Provider>
  )
}

export default UserProvider
